import { BaseTheme } from './theme.interface';
import palette from './palette';

// base colors that we use throughout app, these are real colors and should be mapped to theme names like 'backgroundColor' in ./theme.js, but sometimes you just need them directly and you can access through styled component props.theme.colors
export const colors = {
  text: palette.greys[0],
  dimText: palette.greys[1],
  primaryLightest: palette.blues[3],
  primary: palette.blues[0],
  primaryDark: palette.blues[1],
  primaryDarker: palette.blues[2],
  secondary: palette.greens[0],
  secondaryAccent: palette.yellows[0],
  secondaryAccentDark: palette.yellows[1],
  tertiaryAccent: palette.greys[2],
  foreground: palette.greys[0],
  background: palette.white,
  surface: palette.white,
  surfaceAccent: palette.greys[3],
  systemAccent: palette.greens[2],
};

export const space = [
  '0rem', // 0px
  '0.25rem', // 4px
  '0.5rem', // 8px
  '0.75rem', // 12px
  '1rem', // 16px
  '1.25rem', // 20px
  '1.5rem', // 24px
  '2rem', // 32px
  '2.5rem', // 40px
  '3rem', // 48px
  '3.5rem', // 56px
  '4rem', // 64px
  '4.5rem', // 72px
  '5rem', // 80px
  '5.5rem', // 88px
  '6rem', // 96px
];

export const sizes = [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80];

export const fontSizes = [
  '0rem', // 0px
  '0.25rem', // 4px
  '0.5rem', // 8px
  '0.75rem', // 12px
  '1rem', // 16px
  '1.25rem', // 20px
  '1.5rem', // 24px
  '1.75rem', // 28px
  '2rem', // 32px
  '2.25rem', // 36px
  '2.5rem', // 40px
  '3rem', // 48px
  '3.5rem', // 56px
  '4rem', // 64px
  '4.5rem', // 72px
  '5rem', // 80px
  '5.5rem', // 88px
  '6rem', // 96px
];
export const fontWeights = [400, 600, 900];
export const radii = [0, 2, 4, 6, 10, 20, 24, 32, 56, 999];
export const zIndices = {
  sun: 9999,
  satellite: 5000,
  skyscraper: 1000,
  towerBlock: 500,
  house: 200,
  chimney: 110,
  bungalow: 100,
  shed: 50,
  postbox: 10,
  curb: 1,
  pavement: 0,
  pothole: -10,
  ditch: -20,
  sewer: -100,
  mine: -300,
  seabed: -1000,
};

export const shadows = {
  aria: `0 0 0 0.075rem #fff, 0 0 0 0.2rem ${colors.foreground}`,
  border: '0px 4px 20px rgba(117, 125, 136, 0.16);',
  cardBorder: '0 6px 20px rgba(0, 0, 0, 0.06)',
};
export const breakpoints = ['43em', '62em', '82em'];

export const mediaQueries = {
  mobile: '@media screen and (max-width: 768px)',
  desktop: `@media screen and (min-width: ${breakpoints[0]})`,
  largeDesktop: `@media screen and (min-width: ${breakpoints[1]})`,
};

const fonts = {
  body: 'ManropeVariable, -apple-system, BlinkMacSystemFont, Arial',
  fun: 'Luckiest Guy, -apple-system, BlinkMacSystemFont, Arial',
};

// TODO: consider aliasing some items like space.small = spacing[0]
const baseTheme: BaseTheme = {
  fonts,
  colors,
  space,
  sizes,
  fontSizes,
  fontWeights,
  radii,
  breakpoints,
  zIndices,
  shadows,
  mediaQueries,
};

// this...complains about react native or something in gatsby
// if you're reading this, this was an attempt to type the theme
// declare module 'styled-components' {
//   // eslint-disable-next-line no-shadow
//   export interface DefaultTheme extends BaseTheme {}
// }

export default baseTheme;
