import React, { FC, useContext } from 'react';
import { useCheckboxGroupItem, useFocusRing, VisuallyHidden } from 'react-aria';
import { AriaCheckboxGroupItemProps } from '@react-types/checkbox';
import { CheckboxGroupState } from 'react-stately';
import { CheckboxGroupContext } from './index';
import { Button } from '../Buttons';

// Exported here because it was throwing an error in the Checkbox Story
// - not able to export a private variable
export interface CheckboxButtonProps extends AriaCheckboxGroupItemProps {
  children: React.ReactNode;
  onPress: () => void;
}

const CheckboxButton: FC<CheckboxButtonProps> = props => {
  const state = useContext(CheckboxGroupContext);
  const ref = React.useRef(null);
  const { onPress, ...rest } = props;
  const { inputProps } = useCheckboxGroupItem(
    props,
    state as CheckboxGroupState,
    ref,
  );
  const { isFocusVisible, focusProps } = useFocusRing();

  const isCheckboxSelected = state ? state.isSelected(props.value) : false;

  return (
    <label style={{ display: 'flex', alignItems: 'center' }}>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <Button
        isFocusVisible={isFocusVisible}
        isSelected={isCheckboxSelected}
        onPress={() => {
          state?.toggleValue(props.value);
          onPress();
        }}
        variant="secondary"
        buttonSize="medium"
        aria-hidden="true"
        role="presentation"
        tabIndex={-1}
        {...rest}
      >
        {props.children}
      </Button>
    </label>
  );
};

export default CheckboxButton;
