/*
 This slice is a catchall for various bits of game state
*/
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import gameConfig from '@content/gameconfig';

type HeaderTypeProps = 'intro' | 'minimal' | 'recall' | 'full' | 'shareable';
type Notification = {
  id: number;
  text: string;
};
interface GameState {
  /** initialized to true once you start the game */
  hasStarted: boolean;
  hasFinished: boolean;
  /** what kind of fixed header the user is seeing currently */
  headerType: HeaderTypeProps;
  /** language setting, we cannot really reasonably infer this as its dynamic, technically the current language code could be Klingon for all we know  */
  currentLanguage: string;
  /** this just keeps track of our notifications in the game, this array is usually 0 until you get notifications, the default behaviour of our app is that notifications disappear after a while */
  languageCodesUsed: string[];
  notifications: Notification[];
  defaultLanguage: string;
}

const langsUsed = gameConfig.Languages_Used;
// make room to set this via env in case we want to host same base site on diff localized urls
export const firstLanguage =
  process.env.GATSBY_DEFAULT_LANG ||
  langsUsed?.[0]?.Content_Type ||
  langsUsed?.Content_Type;

export const initialGameState: GameState = {
  hasStarted: false,
  hasFinished: false,
  headerType: 'intro',
  defaultLanguage: firstLanguage,
  currentLanguage: firstLanguage,
  languageCodesUsed:
    langsUsed?.length > 0
      ? langsUsed.map(l => l.Content_Type)
      : [langsUsed?.Content_Type],
  notifications: [],
};

let internalNotificationCount = 0;

export const gameStateSlice = createSlice({
  name: 'gameState',
  initialState: initialGameState,
  reducers: {
    resetGame: state => ({
      ...initialGameState,
      // keep our settings/lang stable
      currentLanguage: state.currentLanguage,
    }),
    gameHasStarted: state => {
      state.hasStarted = true;
    },
    gameHasFinished: state => {
      state.hasFinished = true;
    },
    setHeaderType: (
      state,
      action: PayloadAction<{ headerType: HeaderTypeProps }>,
    ) => {
      state.headerType = action.payload.headerType;
    },
    setCurrentLanguage: (
      state,
      action: PayloadAction<{ setTo: string; eventType?: string }>,
    ) => {
      state.currentLanguage = action.payload.setTo;
    },
    addNewNotification: (state, action: PayloadAction<string>) => {
      state.notifications = [
        ...state.notifications,
        {
          id: (internalNotificationCount += 1),
          text: action.payload,
        },
      ];
    },
    removeAllNotifications: state => {
      state.notifications = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  gameHasStarted,
  gameHasFinished,
  resetGame,
  setHeaderType,
  setCurrentLanguage,
  removeAllNotifications,
  addNewNotification,
} = gameStateSlice.actions;

export default gameStateSlice.reducer;
